{
  "name": "peptide-dashboard",
  "version": "0.19.0",
  "description": "Peptide Dashboard",
  "browserslist": "> 0.5%, last 2 versions, not dead",
  "scripts": {
    "start": "parcel src/index.html",
    "build": "parcel build src/index.html --no-cache --no-scope-hoist --public-url ./"
  },
  "author": "Andrew White, Mehrad Ansari",
  "license": "GNUv3",
  "dependencies": {
    "@parcel/transformer-vue": "^2.0.1",
    "@tensorflow/tfjs": "3.13.0",
    "axios": "^0.24.0",
    "bulma": "^0.9.3",
    "d3": "^5.0.0",
    "lodash": "^4.17.21",
    "parcel": "^2.0.1",
    "vue": "^3.2.20"
  },
  "devDependencies": {
    "@parcel/transformer-sass": "^2.0.1",
    "eslint": "^8.2.0",
    "eslint-plugin-vue": "^8.0.3"
  }
}
