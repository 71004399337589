{
  "A": 0.75,
  "R": 0.96,
  "N": 1.26,
  "D": 1.07,
  "C": 0.8,
  "Q": 0.83,
  "E": 0.80,
  "G": 1.47,
  "H": 0.96,
  "I": 0.74,
  "L": 0.75,
  "K": 0.90,
  "M": 0.70,
  "F": 0.76,
  "P": 1.64,
  "S": 1.21,
  "T": 0.98,
  "W": 0.79,
  "Y": 0.98,
  "V": 0.73
}